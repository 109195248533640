<template lang="html">
  <section class="reset-password">
    <div class="container login column-center">
      <div class="contenedor-login">
        <router-link :to="{ name: 'login'}" class="volver">Volver</router-link>
        <h1 class="titulo-formulario-login recordar">
          Restablecer contrase<span class="sombrero">n</span>a
        </h1>
        <Form
          :validation-schema="schema"
          v-slot="{ errors }"
          @submit="onSubmit"
        >
          <div>
            <Field
              name="email"
              class="input-styled"
              placeholder="Email"
              :class="{ error: errors.email }"
              v-model="data.email"
            />
            <span class="mss-error">{{ errors.email }}</span>
          </div>
          <div class="column contenedor-pass">
            <Field
              name="password"
              class="input-styled"
              placeholder="Contraseña"
              :type="showPassword ? 'text' : 'password'"
              :class="{ error: errors.password_confirmation }"
              v-model="data.password"
            />
            <span class="mss-error">{{ errors.password }}</span>
            <a>
             <i @click="showPassword=!showPassword" :class="[this.showPassword ? 'fa-eye' : 'fa-eye-slash', 'fa']"></i>
            </a>
          </div>

          <div class="column contenedor-pass">
            <Field
              name="password_confirmation"
              class="input-styled"
              placeholder="Repite contraseña"
              :type="showPassword ? 'text' : 'password'"
              :class="{ error: errors.password_confirmation }"
              v-model="data.password_confirmation"
            />
            <span class="mss-error">{{ errors.password_confirmation }}</span>
          </div>
          <div>
            <button class="boton-styled">Solicitar</button>
          </div>
        </Form>
      </div>
    </div>
  </section>
</template>

<script lang="js">
import { Form, Field, defineRule, configure, ErrorMessage } from "vee-validate";
import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
  name: 'reset-password',
  props: [],

  data() {
    const schema = {
      email: 'required|email',
      password: 'required|min:8',
      password_confirmation: 'confirmed:@password',
    };
    return {
      schema,
      showPassword: false,
      data: {
        email: '',
        password: '',
        password_confirmation: '',
        token: this.$attrs['token']
      }
    };
  },
  components: {
    Field,
    Form
  },
  methods: {
    ...mapActions({
      resetPaswsword: 'auth/resetPaswsword',
    }),
    ...mapMutations({
      launchAlert: "alert/launchAlert",
    }),
    onSubmit() {
      this.resetPaswsword(this.data).then(r => {
        if (r) {
          this.launchAlert('Se ha realizado con éxito.');
          this.$router.push({ name: 'login' });
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.mss-error {
  display: inline-block;
  text-align: left;
  width: 100%;
  max-width: 285px;
  color: #d32626;
}
.contenedor-login .input-styled {
  margin-bottom: 0;
}
</style>
